import React, { useEffect, useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import { plazaServices } from '../tollPlaza/plazaReportService';
import { FormFilter } from './InputComponents';
import { GeneralInput } from './InputComponents';
import axios from 'axios';
import { Skeleton } from 'primereact/skeleton';
import { RadioButton } from "primereact/radiobutton";
import { Messages } from 'primereact/messages';
import { dateFormatter } from '../../utilities/utillFunction';
import { InputText } from 'primereact/inputtext';
export const UserGroup = () => {
    const [groupList, setGroupList] = useState()
    const [specificUser, setSpecificUser] = useState()
    const [input, setInput] = useState()
    const [showPopup, setShowPopup] = useState({ addGroup: false, editGroup: false })
    const [userList, setUserList] = useState()
    const [poc, setPoc] = useState()
    const [projects, setProjects] = useState();
    const [slectedProject, setSelectedProject] = useState()
    const [groupDetails, setGroupDetails] = useState()
    const [filteredData, setFilteredData] = useState()
    const msgs = useRef(null);
    const deleteMsg = useRef(null)
    const serviceId = localStorage.getItem("serviceId");
    const refresh = async () => {
        let res = await plazaServices.general(serviceId, { "filter": {}, "sort": [["Created_time", -1]] }, "nq-user-groups");
        setGroupList(res)
        setFilteredData(res);
    }
    useEffect(() => {
        const userPayload = {
            filter: {
                "user.role": "Vendor",
                "serviceId": serviceId
            },
            projection: { user: 1, image: 1 }
        }

        Promise.all([plazaServices.general(serviceId, { "filter": {}, "sort": [["Created_time", -1]] }, "nq-user-groups"), plazaServices.general("nextqore", userPayload, "nq-userpermissions"), plazaServices.general("nextqore", {}, "nq-file-utility-project-types")]).then((res) => {
            setGroupList(res[0])
            setFilteredData(res[0])
            let userArr = res[1].map((item) => {
                return {
                    name: item.user.email,
                    label: item.user.name
                }
            })
            let arr = res[2].map((item) => {
                return {
                    name: item.name,
                    label: item.name
                }
            })
            setProjects(arr);
            setUserList(userArr)
        })
    }, [])

    const skeltonShow = (ind) => {
        return <Skeleton
            className="lg:col-4 col-12"
            key={ind}
            style={{ width: '135px', height: '135px' }}
        />
    }
    const addGroup = () => {
        return <div className='col-12 grid   justify-content-center'>
            <div className='col-12 grid  justify-content-center'>
                <div className='grid justify-content-center col-12 '>
                    <GeneralInput placeHolder="Group" labelName="Enter Vendor Name:" label={true} Func={setInput} data={input} />
                    <FormFilter placeHolder="Select Project Type" labelName="Select Project  Type" label={true} userList={projects} Func={setSelectedProject} selected={slectedProject} single={false} />
                    <FormFilter placeHolder="Select Users" labelName="Select Users:" label={true} userList={userList} Func={setSpecificUser} selected={specificUser} single={false} />
                    {specificUser && specificUser.length ? <div className='col-12 w-full'>
                        <p className='chartHeading'>Select Poc User</p>
                        {specificUser && <DataTable value={specificUser ?? []}>
                            <Column
                                headerClassName="colorX"
                                style={{ width: "3.75rem" }}
                                header={"Name"}
                                field={"label"}
                            />
                            <Column
                                headerClassName="colorX"
                                style={{ width: "3.75rem" }}
                                header={""}
                                body={(e) => {
                                    return <><RadioButton className='m-0 p-0' inputId={e.name} name={e.name} value={e.name} onChange={(e) => setPoc(e.value)} checked={poc === e.name} /></>
                                }}
                            />
                        </DataTable>}
                    </div> : ""}
                    <Messages ref={msgs} className='col-12 text-l' />
                </div>
                <Button
                    label="Create Vendor"
                    style={{
                        cursor: "pointer",
                        float: "right",
                        backgroundColor: "rgb(12, 98, 145)",
                        color: "white",
                        border: "2px solid rgb(12, 98, 145)",
                        borderRadius: "0.375rem",
                        fontWeight: "300"
                    }}
                    className=" mb-2 text-l "
                    onClick={addGroupHandler}
                />
            </div>
        </div>
    }
    const ConfirmDelte = () => {
        const deleteGroup = () => {
            axios.post(`${process.env.REACT_APP_API_PARSER_URL}/fileSharingUtility/deleteUserGroup/`, {
                serviceId: serviceId,
                groupName: groupDetails && groupDetails.group
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    deleteMsg.current.state.messages = []
                    deleteMsg.current.show({ id: '1', sticky: true, severity: 'info', summary: '', detail: ` ${groupDetails && groupDetails.group} Group SucessFully deleted`, closable: true });
                    refresh()
                    setTimeout(() => { setShowPopup({ addGroup: false, editGroup: false }) }, 2000)
                })
                .catch((error) => {
                    console.error('Error:', error)
                });
        }
        return <div className=' grid col-12 generalCard justify-content-center'>
            <p className='text-xl mt-2'> Are you sure you want to delete group {groupDetails && groupDetails.group}</p>
            <div className='col-12 grid justify-content-center mt-2 '>
                <Button label="Cancel" style={{ backgroundColor: "white", color: "black", border: "1px solid black", borderRadius: "6px" }} className='lg:col-4 col-8  mb-2 text-xl' onClick={() => { setShowPopup({ addGroup: false, editGroup: false }) }} />
                <Button label="Delete" onClick={deleteGroup} style={{ backgroundColor: "#F67E6D", color: "white", borderRadius: "6px" }} className='lg:ml-2 lg:col-4 mb-2 col-8  text-xl' />
                <Messages ref={deleteMsg} className=' text-l' />
            </div>
        </div>
    }
    const groupCard = (item, idx) => {
        const colorArr = ["#B1D760", "#F67E6D", "#F9B65D", "#FCED66", "#FCED66", "#FBCEE6", "#BDB8DB", "#BD7EBE"]
        let poc = item.users?.filter(cur => cur.isPOC)
        item.projectType = item.projectType ? Array.isArray(item.projectType) ? item.projectType.join(",") : item.projectType : ""
        let users = (curr, idx) => {
            idx++;
            let ind = 8 % (idx);
            return <div key={idx} className='flex  flex-wrap   align-items-center m-0 p-0  col-12' style={{ lineHeight: "0" }}>
                {<p style={{ color: "white", height: "3rem", width: "3rem", borderRadius: "50%", backgroundColor: `${colorArr[ind]}` }} className='mt-2 text-xl inline-flex justify-content-center align-items-center' >{curr.email && curr.email.length && curr.email[0].toUpperCase() + curr.email[1].toUpperCase()}</p>}
                <p className='lg:text-xl text-l m-0 p-0 inline-flex flex-column ml-2' style={{ color: "black" }} >{curr.email} <span className='text-sm m-0 p-0 font-bold' style={{ color: "#5E6771" }}>{curr.name}</span></p>
            </div>
        }
        return (
            <div key={idx} className='lg:col-4 col-12  ' >
                <div
                    className='col-12 justify-content-center grid  card p-0 m-0'
                    style={{
                        fontStyle: "Inter",
                        borderRadius: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <div
                        className='chartHeading grid col-12 justify-content-between align-items-center m-0 p-2'
                        style={{
                            backgroundColor: "rgb(12, 98, 145)",
                            borderTopRightRadius: "0.5625rem",
                            borderTopLeftRadius: "0.5625rem",
                        }}
                    >
                        <p className='m-0 p-0 chartHeading' style={{ color: "white" }}>{item && item.group && item.group.charAt(0).toUpperCase() + item.group.slice(1)}</p>
                        {/*<i
                            className="pi pi-trash"
                            style={{ color: "white", cursor: "pointer" }}
                            onMouseEnter={(e) => (e.target.style.color = "red")}
                            onMouseLeave={(e) => (e.target.style.color = "white")}
                            onClick={() => {
                                setShowPopup({ ...showPopup, editGroup: true });
                                setGroupDetails(item);
                            }}
                        ></i>*/}
                    </div>
                    <div
                        className='col-12 grid justify-content-between  text-l font-medium'
                        style={{ color: "#5E6771", lineHeight: "0" }}
                    >
                        <p className='col-12 mt-2 inline-flex justify-content-between' style={{ lineHeight: "0" }}>Created By <span className='' style={{ color: "#1D2129", fontSize: "1rem" }}>{item.Created_by?.email}</span></p>
                        <p className='col-12 inline-flex justify-content-between' style={{ lineHeight: "0" }}> Created On <span className='' style={{ color: "#1D2129", fontSize: "1rem" }}>{item && typeof (item["Created_ts"]) === "string" ? dateFormatter(item["Created_ts"], null, "YYYY-MM-DD HH:mm:ss") : dateFormatter(item["Created_ts"])}</span></p>
                        <p className='col-12 inline-flex justify-content-between' style={{ lineHeight: "0" }}>Point of Contact <span className='' style={{ color: "#1D2129", fontSize: "1rem" }}>{poc[0]?.name}</span></p>
                        <p className='col-12 inline-flex justify-content-between' style={{ lineHeight: "0" }}>Work Category <span className='' style={{ color: "#1D2129", fontSize: "1rem" }}>{item.projectType}</span></p>
                    </div>
                    <hr style={{ border: "1px solid #ccc", width: "100%", margin: "0.6rem 0" }} />
                    <p className='inline-flex col-12 chartsubHeading m-0' style={{ lineHeight: "0" }}>Users</p>
                    <div
                        style={{
                            maxHeight: "7rem",
                            overflow: "auto",
                            listStyle: "none",
                            color: "black",
                            lineHeight: "2",
                            flexGrow: 1,
                        }}
                        className='col-12  text-l font-normal m-0'
                    >
                        {item && item.users.map((curr, idx) => (
                            users(curr, idx)
                        ))}
                    </div>
                </div>
            </div>
        );
    };
    const addGroupHandler = async () => {
        let data = specificUser ? specificUser.map((item) => { return item.name }) : [];
        let ProjectType = slectedProject ? slectedProject.map((item) => { return item.name }) : [];
        if (input && data.length && slectedProject && poc) {
            axios.post(`${process.env.REACT_APP_API_PARSER_URL}/fileSharingUtility/userGroupAddEdit/`, {
                serviceId: serviceId,
                userGroup: input,
                userIDs: data,
                groupName: input,
                "userEmail": localStorage.getItem("emailId"),
                projectType: ProjectType,
                pocID: poc,
                edit: false
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    msgs.current.state.messages = []
                    msgs.current.show({ id: '1', sticky: true, severity: 'info', summary: '', detail: 'Group SucessFully Created', closable: true });
                    refresh()
                    setTimeout(() => {
                        setShowPopup({ addGroup: false, editGroup: false })
                        setInput()
                        setSelectedProject()
                        setSpecificUser()
                        setPoc()
                    }, 2000)
                })
                .catch((error) => {
                    console.log("err", error)
                    msgs.current.state.messages = []
                    msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `Group Not Created `, closable: true });
                    console.error('Error:', error)
                });
        }
        else {
            msgs.current.state.messages = []
            msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `Please Fill all Fields`, closable: true });
        }
    }
    return (
        <div className='col-12 grid justify-content-between m-0 p-0 w-full gap-2'>
            <div className="col-12 grid m-0 p-0 gap-2 w-full">
                <div className="col-12 grid mb-2 mt-2 p-0 w-full align-items-center lg:justify-content-between justify-content-center">
                    <div className="flex flex-wrap col-12 lg:col-3 m-0 p-0 justify-content-between align-items-center">
                        <p className="chartHeading text-2xl p-0 lg:ml-3 ml-0 lg:col-4 col-12">
                            Vendors
                        </p>
                        <InputText
                            id="tollDropdown"
                            placeholder="Search"
                            onChange={(e) => {
                                const searchText = e.target.value;
                                const filteredArr = groupList.filter((item) => {
                                    const itemStr = JSON.stringify(item);
                                    return itemStr.includes(searchText);
                                });
                                setFilteredData(filteredArr);
                            }}
                            className="p-dropdown flex align-items-center bg-white mb-3 w-full lg:w-auto"
                            style={{
                                borderRadius: '20px',
                                border: '2px solid #0C6291',
                            }}
                        />
                    </div>
                    <div className="m-0 lg:col-4 col-12">
                        <Button
                            label="Add Vendor"
                            icon="pi pi-plus"
                            style={{
                                cursor: 'pointer',
                                float: 'right',
                                backgroundColor: 'rgb(12, 98, 145)',
                                color: 'white',
                                border: '2px solid rgb(12, 98, 145)',
                                borderRadius: '0.375rem',
                                fontWeight: '300',
                            }}
                            className="m-0 text-l"
                            onClick={() => {
                                setShowPopup({
                                    ...showPopup,
                                    addGroup: true,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='col-12 grid m-0 p-0 w-full gap-2  '>
                {
                    filteredData ? filteredData.map((item, idx) => {
                        return groupCard(item, idx)
                    }) : [1, 2, 3].map((item, ind) => {
                        return skeltonShow(ind)
                    })
                }
            </div>
            <Dialog header={showPopup["addGroup"] ? "Create Vendor" : "Delete Group"} className='lg:col-6 col-12' visible={showPopup["addGroup"] || showPopup["editGroup"]} modal onHide={() => {
                setShowPopup({ addGroup: false, editGroup: false })
                setInput()
                setSelectedProject()
                setSpecificUser()
                setPoc()
            }}>
                <div className="col-12">
                    {showPopup["addGroup"] ? addGroup() : ConfirmDelte()}
                </div>
            </Dialog>
        </div>
    )
}
