import apiService from "../../service/apiService"
import { httpConstants } from "../../lib/constants"

const addFeed = async (Formdata, files = []) => {
    const cloneFormData = (formData) => {
        const newFormData = new FormData();
        for (let [key, value] of formData.entries()) {
            newFormData.append(key, value);
        }
        return newFormData;
    };
    let successRes = 0
    if (files.length > 0) {
        const promiseArr = []
        for (let i = 0; i < files.length; i++) {
            let formdata= cloneFormData(Formdata)
            formdata.set("file", files[i])
            if (i > 0 && formdata.has("comment")) {
                formdata.delete("comment")
            }
            promiseArr.push(callFeedApi(formdata))
        }
        let res = await Promise.all(promiseArr).catch(err => console.error(err))
            if (res) {
                successRes++
            }
    }
    else {
        const res = await callFeedApi(Formdata)
        if(res) successRes++
    }
    async function callFeedApi(formdata) {
        const url = process.env.REACT_APP_API_PARSER_URL + "/fileSharingUtility/feedCreateEdit/"
        const header = { "Content-Type": httpConstants.CONTENT_TYPE.MULTIPART_FORM_DATA }
        let res = await apiService.httpService(httpConstants.METHOD_TYPE.POST, formdata, url, header).catch(err => {
            console.error(err)
        })
        return res
    }
    return successRes
}

export const filename = (filekey) => {
    if (filekey) {
        let splitFilekey = filekey.split("/")
        if (splitFilekey.length) {
            if (splitFilekey[splitFilekey.length - 1] !== "/") {
                return splitFilekey[splitFilekey.length - 1]
            }
        }
    }
    return "--"
}


const service = {
    addFeed
}

export default service