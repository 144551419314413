import React, { useEffect, useState, useRef } from "react";
import { TabPanel, TabView } from 'primereact/tabview'
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Avatar } from "primereact/avatar";
import { httpConstants } from "../../lib/constants";
import moment from "moment";
import { assignment_status_color} from "../../utilities/constant";
import { Messages } from 'primereact/messages';
import { Calendar } from "primereact/calendar";
import { Timeline } from "primereact/timeline";
import fileUtilityService, { filename } from "./fileUtility"
import apiService from "../../service/apiService"
import { dateFormatter } from "../../utilities/utillFunction";
import {ProgressBar} from "primereact/progressbar"
import { Dialog } from "primereact/dialog";

const FeedsPage = ({ assignment = {}, projectName, assignSort, setShowDetails, assignmentStatuses=[], isProjectCompleted=true }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [allFeeds, setallFeeds] = useState([])
    const [enddt, setEndDt] = useState(null)
    const [visibleUpdEndDtBtn, setVisibleUpdEndDtBtn] = useState(false)
    const latestFeeds = useRef([])
    const [files, setFiles] = useState([])
    const [comment, setComment] = useState("")
    const serviceId = localStorage.getItem("serviceId")
    const emailId = localStorage.getItem("emailId")
    let { assignmentName, status, deliveryDate } = assignment
    const [isVendor, setIsVendor] = useState(false)
    const [feedAdded, setFeedAdded] = useState(0)
    const msgs = useRef(null);
    const addFeedMsgs = useRef(null)
    const feedContainer = useRef(null);
    const fileUploadRef = useRef(null)
    const [showAck, setShowAck] = useState(false)
    const [showProgress, setShowProgress] = useState(false)
    const [displayConfirmation, setDisplayConfirmation] = useState(false)
    const assignmentStatusMap = assignmentStatuses.length && assignmentStatuses.reduce((map, item)=> { 
        map[item.name] = {title :item.label, value: item.name}
        return map;
    }, {})
    
    useEffect(() => {
        if (assignmentName && projectName) {
            const payload = {
                serviceId: serviceId,
                userEmail: emailId,
                projectName: projectName,
                assignmentName: assignmentName
            }
            const url = process.env.REACT_APP_API_PARSER_URL + "/fileSharingUtility/listFeeds/"
            apiService.httpService(httpConstants.METHOD_TYPE.POST, payload, url).then(res => {
                if (res.Feeds && res.Feeds.length) {
                    let isFalse = false
                    latestFeeds.current = res.Feeds.filter((item) => {
                        if(!item.isNew && !deliveryDate && !feedAdded) isFalse = true
                        let threshold_date = moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")
                        let feed_date = dateFormatter(item.modified_ts, "YYYY-MM-DD HH:mm:ss")
                        return (feed_date > threshold_date || item.isNew)})
                    const sorted_feeds = res.Feeds.sort((a, b) => a.modified_ts - b.modified_ts)
                    setallFeeds(sorted_feeds)
                    setShowAck(isFalse)
                }
            })
        }
    }, [feedAdded])


    const emptyFeedMsg = (message="") => {
        return (
            <div className="empty-msg text-500">
                <span className="pi pi-comments text-5xl"></span>
                <p>{message}</p>
            </div>
        )
    }
    const handleDateChange = (e) => {
        let selDt = new Date(e.value).getTime()
        setEndDt(selDt)
        if (assignment.deliveryDate != selDt) {
            setVisibleUpdEndDtBtn(true)
        }
    }

    const onDateSave = async () => {
        if (!enddt) {
            msgs.current.show([{ severity: 'warn', summary: '', detail: 'Please Select Delivery date', life: 2000, closable: false }])
            return
        }
        if (enddt) {
            let data = { serviceId: serviceId, userEmail: emailId, projectName: projectName, assignmentName: assignmentName, deliveryDate: enddt }
            let url = process.env.REACT_APP_API_PARSER_URL + "/fileSharingUtility/assignementDeliveryDate/"
            let res = await apiService.httpService('post', data, url)
            if (res && res.message) {
                changeAssignmentStatus(assignmentStatusMap["Acknowledged"]["value"]).then((res) => {
                    if (res) {
                        assignment.status = assignmentStatusMap["Acknowledged"]["value"]
                        assignment.deliveryDate = enddt
                        assignSort(assignment)
                        msgs.current.show([{ severity: 'success', summary: '', detail: 'Delivery date updated successfully', life: 2000, closable: false }])
                        setVisibleUpdEndDtBtn(false)
                    }
                })
            }
        }
    }

    const onFileSelect = (e) => {
        const filelist = files
        if (e.files.length > 0) {
            for (let i = 0; i < e.files.length; i++) {
                filelist.push(e.files[i])
            }
            setFiles(filelist)
        }
    };

    const validation = () => {
        if (!comment.trim() && !files.length) return "please add comment or file"
    }

    const addFeed = async () => {
        const validationres = validation()
        if (typeof (validationres) === "string") {
            addFeedMsgs.current.show([{ severity: 'warn', summary: "", detail: "Please add comment or file"}]);
            return
        }
        setShowProgress(true)
        if (comment || files.length) {
            let new_status = assignment.status
            if (isVendor) new_status = assignmentStatusMap["SubmittedForReview"]["value"]
            else if (new_status !== assignmentStatusMap["Active"]["value"] && new_status !== assignmentStatusMap["Acknowledged"]["value"]) new_status = assignmentStatusMap["NeedsModification"]["value"];
            const formData = new FormData()
            formData.append("serviceId", serviceId)
            formData.append("userEmail", emailId)
            formData.append("projectName", projectName)
            formData.append("assignmentName", assignmentName)
            formData.append("feedId", Date.now())
            formData.append("status", new_status)
            if (comment) formData.append("comment", comment)
            const res = await fileUtilityService.addFeed(formData, files).catch(err => {
                setShowProgress(false)
                addFeedMsgs.current.show([{ severity: 'error', summary: '', detail: 'Activity Add Failed', life: 2000, closable: false }])
                console.error("feed upload failed", err)
            })
            if (res > 0) {
                changeAssignmentStatus(new_status).then((res) => {
                    if (res) {
                        assignment.lastActivity = Date.now()
                        assignment.status = new_status
                        assignment.comment = comment
                        let newFeedCount = feedAdded + 1
                        setFeedAdded(newFeedCount)
                        setComment("")
                        setFiles([])
                        assignSort(assignment)
                        setShowProgress(false)
                        addFeedMsgs.current.show([{ severity: 'success', summary: '', detail: 'Activity Added', life: 2000, closable: false }])
                        if (fileUploadRef.current) {
                            fileUploadRef.current.clear();
                        }
                    }
                }).catch(err => {
                    setShowProgress(false)
                addFeedMsgs.current.show([{ severity: 'error', summary: '', detail: 'Assginment Status Change Failed', life: 2000, closable: false }])
                console.log("Change Assignement Status Error", err)
            })
            }
        }
    }

    useEffect(() => {
        if (activeIndex === 2) {
            const el = feedContainer.current;
            if (el) {
                setTimeout(() => {
                    el.scroll({
                        top: el.scrollHeight,
                        behavior: 'smooth'
                    });
                }, 1);
            }
        }
    }, [activeIndex])

    useEffect(() => {
        const payload = {
            filter: {
                "user.email": emailId,
                "serviceId": serviceId
            },
            projection: {
                "_id": 0,
                "user": 1
            }
        }

        apiService.httpDBService(payload, "nextqore", "nq-userpermissions").then((res => {
            if (res && res.length) {
                let isVendor = res[0].user.role === "Vendor" ? true : false
                setIsVendor(isVendor)
            }
        }))
    }, [])

    const statusStyle = (status = "") => {
        let style = {}
        if (status in assignment_status_color) {
            style = assignment_status_color[status]
        }
        return style
    }

    const content = (item) => {
        let feedId = item.feedId
        return <div className="displayCard mb-5 shadow-4" style={{ backgroundColor: item.status && assignment_status_color[item.status] && assignment_status_color[item.status]["background"] }}>
            <ActivityCard feed={item} filename={filename} key={feedId} emailId={emailId} statusStyle={statusStyle} assignmentStatusMap={assignmentStatusMap} />
        </div>
    }

    const marker = (item) => {
        return (
            <span className="custom-marker shadow-2 bg-cyan-500 p-2">
                <i className="pi pi-clock"></i>
            </span>
        );
    };
    const changeAssignmentStatus = async (status) => {
        const payload = {
            "serviceId": serviceId,
            "userEmail": emailId,
            "projectName": projectName,
            "assignmentName": assignmentName,
            "edit": true,
            "status": status
        }
        let url = process.env.REACT_APP_API_PARSER_URL + "/fileSharingUtility/assignementAddEdit/"
        let res = await apiService.httpService(httpConstants.METHOD_TYPE.POST, payload, url)
        return res
    }

    const onApprove = async (e) => {
        let res = await changeAssignmentStatus(assignmentStatusMap["Approved"]["value"])
        if (res && res.message) {
            assignment.lastActivity = Date.now()
            assignment.status = assignmentStatusMap["Approved"]["value"]
            assignSort(assignment)
            setDisplayConfirmation(false)
        }
    }
    const basicDialogFooter = <Button type="button" label="OK" onClick={() => setShowAck(false)} icon="pi pi-check" className="p-button-secondary" />;
    
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" style={{color : "red"}} onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={onApprove} className="p-button-text" autoFocus />
        </>
    );

    const deliveryDateStyle = () => {
        let style = {}
        const today = moment().format("YYYY-MM-DD")
        const deliverydate = dateFormatter(deliveryDate, "YYYY-MM-DD")
        if(today > deliverydate && status !== assignmentStatusMap["Approved"]?.value){
            style.color = "red"
        }
        return style
    }

    if(!assignmentStatuses.length) return <p>No Statuses Found</p>
    else return (
        <>
            <Dialog header="Info" visible={isVendor && showAck} style={{ width: '30vw' }} modal footer={basicDialogFooter} onHide={() => setShowAck(false)}>
                <p className="text-lg text-800 font-bold text-center">
                  Please Add Delivery Date to Acknowledge the Assignment
                </p>
            </Dialog>
            <Button
                label=""
                icon="pi custom-pi pi-arrow-left"
                style={{ backgroundColor: "#0C6291", color: "white", border: "2px solid rgb(12, 98, 145)", borderRadius: "6px", fontWeight: "300" }}
                className=" text-l lg:ml-3 ml:0"
                onClick={() => { setShowDetails("") }}
            />
            <div className="col-12 flex flex-wrap">
                <div className="lg:col-4 col-12">
                    <div className="card shadow-2" style={{ "borderRadius": "1rem", height: "100%" }}>
                        <h4 className="text-center m-0">Assignment Details</h4>
                        <div className="">
                            <div className="flex flex-grow-1">
                                <p className="text-base col-5 p-0 font-bold m-0">Assignment name :</p>
                                <p className="text-base col-7 p-0 font-bold m-0" style={{ wordBreak: "break-all" }}>{assignmentName}</p>
                            </div>
                            <div className="flex flex-grow-1">
                                <p className="text-base col-5 p-0 font-bold m-0">Created On :</p>
                                <p className="text-base col-7 p-0 font-bold m-0">{dateFormatter(assignment?.Created_ts) ?? "--"}</p>
                            </div>
                            <div className="flex flex-grow-1">
                                <p className="text-base col-5 p-0 font-bold m-0">Created By :</p>
                                <p className="text-base col-7 p-0 font-bold m-0">{assignment?.Created_by?.name ?? "--"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-4 col-12">
                    <div className="card shadow-2" style={{ height: "100%", borderRadius: "1rem" }} >
                        <h4 className="text-center m-0 mb-4">Assignment Status</h4>
                        <div className="text-center">
                            <span className="status-badge text-center text-xl px-5" style={statusStyle(status)}>{assignmentStatusMap[status] && assignmentStatusMap[status]?.title}</span>
                        </div>
                    </div>
                </div>
                <div className="lg:col-4 col-12" >
                    <div className="card shadow-2" style={{ height: "100%", "borderRadius": "1rem" }}>
                        <h4 className="text-center m-0 mb-1">Delivery Date</h4>
                        {(!deliveryDate && isVendor && status === assignmentStatusMap["Active"]["value"])
                            ? <div className="">
                                <Calendar id="enddt" value={enddt} placeholder="Select Date" onChange={handleDateChange} showIcon readOnlyInput className="p-calendar flex align-items-center bg-white" style={{ borderRadius: '17px', border: '2px solid #0C6291', width: "100%" }} minDate={new Date(moment())}/>
                                {visibleUpdEndDtBtn && <Button label="Acknowledge" className="m-2" onClick={onDateSave} />}
                            </div>
                            : <p className="text-xl my-2 text-center font-bold m-0" style={deliveryDateStyle()}>{deliveryDate ? dateFormatter(deliveryDate, "YY, MMM DD") : "--"}</p>}
                        <Messages ref={msgs} />
                        {(!isVendor && status !== assignmentStatusMap["Active"]["value"] && deliveryDate) &&
                            <div className="text-center">
                                {status !== assignmentStatusMap["Approved"]["value"] && <Button label="APPROVE" onClick={() => setDisplayConfirmation(true)} disabled={showProgress} className="p-button-raised p-button-success mr-2 mb-2" />}
                                <Dialog header="Approve and Close Assignment" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                                    <div className="flex align-items-center justify-content-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem', color : "#f0ad4e" }} />
                                        <span>Are you sure you want to proceed?</span>
                                    </div>
                                </Dialog>
                            </div>}
                    </div>
                </div>
            </div>
            <div className="col-12">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Latest Activity">
                        {latestFeeds.current.length ? latestFeeds.current.map((item, idx) => (
                            <div id="" className="col-12" key={idx}>
                                <div className="card" style={{ borderRadius: "1rem" }}>
                                    <ActivityCard filename={filename} feed={item} key={idx} emailId={emailId} statusStyle={statusStyle} assignmentStatusMap={assignmentStatusMap} />
                                </div>
                            </div>)) : emptyFeedMsg("No New Feeds")}
                    </TabPanel>
                    <TabPanel header="Add feed">
                        {isProjectCompleted ? emptyFeedMsg("Project is Closed") :  (isVendor && (status === assignmentStatusMap["Active"]["value"])) ? emptyFeedMsg("Please add Delivery Date and Acknowledge the assignment to add Activity") : (isVendor && (status === assignmentStatusMap["Approved"]["value"])) ? emptyFeedMsg("Assignment is Approved") : 
                        <>
                            <Messages ref={addFeedMsgs} />
                            <div className="flex flex-wrap m-2 align-items-center bg-white p-1">
                                <div className="lg:col-11 col-12">
                                    <textarea cols="30" className="w-full outline-none border-none" rows="6" style={{ backgroundColor: "transparent" }} value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Add your comment..." ></textarea>
                                </div>
                                <div className="lg:col-1 col-12">
                                    <Button icon="pi pi-send" label="Send" onClick={addFeed} className="p-button-rounded p-button" disabled={showProgress} aria-label="Submit" />
                                </div>
                            </div>
                            {showProgress && <ProgressBar  mode="indeterminate"className="mx-2" style={{ height: '6px' }}></ProgressBar>}
                            <div className='col-12'>
                                <FileUpload name="demo[]" ref={fileUploadRef} customUpload={true} auto={true} multiple={true} showUploadButton={false} onSelect={onFileSelect}
                                onRemove={(e)=>{
                                    let arr = files.filter((item)=>item.name!==e?.file?.name)
                                    setFiles(arr)
                                }}
                                 onClear={() => setFiles([])}
                                    mode="advanced" />
                            </div>
                        </>}
                    </TabPanel>
                    <TabPanel header="All Activity">
                        <div className="card widget-timeline overflow-auto" ref={feedContainer} style={{ height: "70vh" }}>
                            {allFeeds.length ? <><h5>All Activity</h5>
                                <Timeline value={allFeeds} align="left" marker={marker} className="customized-timeline" content={content} />
                            </> : emptyFeedMsg("No Feeds")}
                        </div>
                    </TabPanel>
                </TabView>

            </div >
        </>)
}


export default FeedsPage


const ActivityCard = ({ filename, feed={}, key, emailId, statusStyle, assignmentStatusMap}) => {
    if(!Object.keys(feed)) return <></>
    return (
        <div className="" key={key}>
            <div className="flex">
                {feed?.Created_by?.email !== emailId && <div>
                    <Avatar label={feed.Created_by?.name?.charAt(0)} className="mr-2 text-xl" style={{ backgroundColor: "#2196F3", color: "#ffffff" }} shape="circle" />
                </div>}
                <div className="w-full">
                    <div className="flex flex-wrap justify-content-between align-items-end">
                        <div>
                            <p className="m-0 text-lg font-bold">{feed.Created_by.email === emailId ? "You" : feed.Created_by?.name} <span className="text-base text-600 ml-3 line-height-1">{moment(feed.Created_ts).format("DD MMM YY, HH:mm")}</span></p>
                            <p className="text-500 mb-1">{feed.Created_by?.email ?? "--"}</p>
                        </div>
                        <span className="status-badge text-center text-xl mb-1 px-5" style={statusStyle(feed.status)}>{assignmentStatusMap[feed.status]?.title || ""}</span>
                    </div>
                    <div className="message-body">
                        <p className=" text-lg my-2 text-700 m-0">{feed.comment}</p>
                        <div className="flex justify-content-between align-items-end m-0 p-0">
                            <span className="font-semibold text-600">Attachments {`(${feed?.files?.length ?? 0})`}</span>
                            {feed.files?.length > 1 && <Button label="Download All" className="m-0 p-0" style={{ float: "right", backgroundColor: "white", color: "blue" }} onClick={() => {
                                (feed && feed.files) && downloadAll(feed.files)
                            }}></Button>}
                        </div>
                        {(feed.files && feed.files.length) && <div className="attachment-area p-2 flex flex-wrap" style={{ backgroundColor: "#E9F0FF" }}>
                            {feed.files.map(file => (
                                <div className="flex lg:col-3 col-12 align-items-center p-0 font-semibold m-1 mb-0 pb-2 file attachment" >
                                    <i className="pi pi pi-paperclip  mr-2 font-bold text-xl" style={{ color: "#2085E9" }}></i>
                                    <p className="" data-pr-tooltip={file.fileKey}><a href={file.download_url} style={{ wordBreak: "break-all" }} >
                                        {filename(file.fileKey)}</a></p>
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const downloadAll = async (arr) => {
    const downloadFile = (item) => {
        return new Promise((resolve, reject) => {
            try {
                const link = document.createElement('a');
                link.href = item.download_url;
                link.setAttribute('download', item.filekey);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve(`Downloaded: ${item.filekey}`);
            } catch (error) {
                reject(`Failed to download: ${item.filekey}`);
            }
        });
    };
    const downloadPromises = arr.map(item => downloadFile(item));
    try {
        const results = await Promise.all(downloadPromises);
        console.log('All downloads completed:', results);
    } catch (error) {
        console.error('One or more downloads failed:', error);
    }
};