import axios from "axios"
import { httpConstants } from "../lib/constants"

const httpDBService = async (payload={}, db, collection, header={},isParser) => {
    let url = `${db}/${collection}`
    if(isParser) url = process.env.REACT_APP_API_PARSER_URL + "/docdb/" + url
    else url = process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + url
    try {
        const res = await axios({
            method: "operation" in payload ? "put" : "post",
            url: url,
            data: payload,
            headers: header,
        })
        if (res.status === 200) return res.data
    }
    catch (err) {
        console.log("Error in HTTPDB service ", err)
        throw err
    }
}

const httpService = async (method, payload, url,header={"Content-Type": httpConstants.CONTENT_TYPE.APPLICATION_JSON}) => {
    try {
        const res = await axios({
            method: method,
            url: url,
            data: payload,
            headers: header,
        });
        if (res.status === 200) return res.data
    } catch (err) {
        console.error("Error in HTTP service:", err);
        throw err
    }
}

const service = {
    httpDBService,
    httpService
}

export default service