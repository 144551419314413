import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"

export const SelectFilter = ({ list, selectedItem, placeHolder, label }) => {
    return <div className='col-12 lg:col-2 p-0 lg:mr-2'>
        {label ? "Select Label" : ""}
        <Dropdown
            id="tollDropdown"
            value={""}
            options={[]}
            onChange={(e) => {
            }}
            placeholder={
                <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                    {placeHolder}
                </span>
            }
            className="p-dropdown flex align-items-center bg-white  mb-3"
            style={{
                borderRadius: '20px',
                border: '2px solid #0C6291',
                width: "100%"
            }}
        />
    </div>
}

export const FormFilter = ({ Func,selected, placeHolder, label, labelName,userList,single }) => {
    return <div className='col-12  p-0 lg:mr-2 flex justify-content-center mb-0'>
        <div className='grid col-12 justify-content-center align-items-center'>
            <div className=' m-0 p-0 col-12'>
            <p className='lg:col-5 col-12 text-xl font-bold m-0 p-0 lg:ml-1' style={{ color: "#5E6771" }}>{label ? labelName : ""}</p>
                {!single?<MultiSelect
                    id="tollDropdown"
                    value={selected}
                    options={userList}
                    onChange={(e) => {
                        Func(e.value)
                    }}
                    placeholder={placeHolder}
                    className="p-dropdown flex align-items-center bg-white  mb-3"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                />:
                <Dropdown
                    id="tollDropdown"
                    value={selected}
                    options={userList}
                    onChange={(e) => {
                        Func(e.value)
                    }}
                    placeholder={placeHolder}
                    className="p-dropdown flex align-items-center bg-white  mb-3"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                />}
            </div>
        </div>
    </div>
}

export const GeneralInput = ({ Func, label, data, labelName }) => {
    return <div className='col-12  p-0 lg:mr-2 flex justify-content-center mb-0'>
        <div className='grid col-12 justify-content-center align-items-center'>
            <div className=' m-0 p-0 col-12'>
            <p className='lg:col-5 col-12 text-xl font-bold m-0 p-0 lg:ml-1' style={{ color: "#5E6771" }}>{label ? labelName : ""}</p>
                <InputText
                    id="tollDropdown"
                    value={data}
                    onChange={(e) => {
                        Func(e.target.value)
                    }}
                    placeholder=''
                    className="p-dropdown flex align-items-center bg-white  mb-3"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                />
            </div>
        </div>
    </div>
}
