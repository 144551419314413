import React, { useState, useEffect, useRef } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Feed from './FeedsPage'
import { Dialog } from 'primereact/dialog'
import { GeneralInput } from './InputComponents';
import { plazaServices } from '../tollPlaza/plazaReportService'
import { Messages } from 'primereact/messages'
import moment from 'moment'
import service from './fileUtility'
import { FileUpload } from 'primereact/fileupload'
import { InputText } from 'primereact/inputtext'
import { dateFormatter } from '../../utilities/utillFunction'
import { assignment_status_color } from '../../utilities/constant'
import { ProgressBar } from 'primereact/progressbar';
import apiService from "../../service/apiService"

export const AssignmentStatusPage = ({ project, Func, isCompleted }) => {
    const [assignmentData, setAssignmentData] = useState([])
    const [assigneList, setAssigneList] = useState()
    const [files, setFiles] = useState([])
    const [selectedAssigne, setSelectedAssigne] = useState()
    const [selectedStatts, setSelectedStatus] = useState()
    const [filteredData, setFilteredData] = useState()
    const [input, setInput] = useState("")
    const [comments, setComments] = useState("")
    const [showAddAssignment, setShowAddAssignment] = useState(false);
    const [showDetails, setShowDetails] = useState(false)
    const [searchText, setSearchText] = useState("")
    const serviceId = localStorage.getItem("serviceId")
    const [assignment, setAssignment] = useState()
    const [refresh, setRefresh] = useState(false)
    const [role, setUserRole] = useState("customer")
    const [statusList, setStatusList] = useState([]);
    const [disable, setDisable] = useState(false);
    const msgs = useRef(null);
    useEffect(() => {
        const userPayload = {
            filter: {
                "user.email": localStorage.getItem("emailId"),
                "serviceId": serviceId
            },
            proection: {
                user: 1
            }
        }

        let assignmentPayload = {
            filter: {
                "projectName": project
            }
        }

        let latestFeed = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        projectName: project
                    }
                },
                { $sort: { "Created_ts": -1 } },
                {
                    $group: {
                        _id: "$assignmentName",
                        latestFeed: { $first: "$$ROOT" }
                    }
                },
                {
                    $project: {
                        _id: 0,
                        assignmentName: "$_id",
                        latestFeed: {
                            Created_time: "$latestFeed.Created_time",
                            Created_ts: "$latestFeed.Created_ts",
                            comment: "$latestFeed.comment",
                            files: "$latestFeed.files",
                            "feedId": "$latestFeed.feedId",
                            modified_ts: "$latestFeed.modified_ts"
                        }
                    }
                }
            ]
        }

        Promise.all([plazaServices.general(serviceId, assignmentPayload, "nq-assignments"), plazaServices.general(serviceId, latestFeed, "nq-feeds"), plazaServices.general("nextqore", userPayload, "nq-userpermissions")]).then((res) => {
            const assigneDet = new Set()
            const creatorsDet = new Set()
            const feedMap = {}
            setUserRole(res && res[2] && res[2].length && res[2][0]?.user?.role)
            res[1].map((item) => {
                if (item.assignmentName) feedMap[item.assignmentName] = item.latestFeed
            })
            res[0].forEach((item) => {
                item["creator"] = item.Created_by.email
                item["assigned"] = item.assigned_to[0]?.email ?? item.assigned_to[0]
                if (item.assignmentName) {
                    item["comment"] = feedMap[`${item.assignmentName}`]?.comment ?? "No comment available";
                    item["files"] = feedMap[`${item.assignmentName}`]?.files ?? [];
                    item["lastActivity"] = feedMap[`${item.assignmentName}`]?.modified_ts ?? null;
                }
                creatorsDet.add(JSON.stringify({ name: item.Created_by.email, label: item.Created_by.name }));
                if (item.assigned_to) {
                    assigneDet.add(JSON.stringify({ name: item.assigned_to[0], label: item.assigned_to[0] }));
                }
            });
            res[0].sort((a, b) => {
                const dateA = a.lastActivity ? new Date(a.lastActivity) : -Infinity;  // Treat null as the oldest
                const dateB = b.lastActivity ? new Date(b.lastActivity) : -Infinity;  // Treat null as the oldest
                return dateB - dateA;
            });
            const assigneList = Array.from(assigneDet).map(item => JSON.parse(item));
            setAssigneList([{ name: "All", label: "All" }, ...assigneList])
            setAssignmentData(res[0])
            setFilteredData(res[0])

        })
    }, [refresh])

    useEffect(() => {
        let getStatus = async () => {
            let res = await plazaServices.general(serviceId, {}, "nq-assignements-statuses");
            let arr = res.map((item) => {
                return {
                    label: item.title,
                    name: item.value,
                }
            })
            arr = [{ label: "All", name: "All" }, ...arr]
            setStatusList(arr)

        }
        getStatus();
    }, [])

    const sortFunction = (assignment) => {
        setAssignment(assignment)
        let assignArray = [...assignmentData];
        assignArray = assignArray.map((item) => {
            if (item.assignmentName === assignment.assignmentName) {
                item = assignment
            }
            return item;
        })
        assignArray.sort((a, b) => {
            const dateA = a.lastActivity ? new Date(a.lastActivity) : -Infinity;
            const dateB = b.lastActivity ? new Date(b.lastActivity) : -Infinity;
            return dateB - dateA;
        })
        setAssignmentData(assignArray);
        setFilteredData(assignArray)
    }
    const SelectFilter = ({ list, selectedItem, placeHolder, label, Func, field }) => {
        return <div className='col-12 lg:col-2 p-0 lg:mr-2'>
            {label ? "Select Label" : ""}
            <Dropdown
                id="tollDropdown"
                value={selectedItem}
                options={list}
                onChange={(e) => {
                    Func(e.value)
                    if (e.value.name !== "All") {
                        let arr = assignmentData.filter(item => item[`${field}`].toLowerCase() === e.value.name.toLowerCase())
                        setFilteredData(arr);
                    }
                    else setFilteredData(assignmentData);
                }
                }
                placeholder={placeHolder}
                className="p-dropdown flex align-items-center bg-white  mb-3"
                style={{
                    borderRadius: '20px',
                    border: '2px solid #0C6291',
                    width: "100%"
                }}
            />
        </div>
    }

    const onFileSelect = (e) => {
        console.log("hit", e)
        const filelist = files
        if (e.files.length > 0) {
            for (let i = 0; i < e.files.length; i++) {
                filelist.push(e.files[i])
            }
            setFiles(filelist)
        }
    };

    const addAssignment = () => {
        let payload = {
            serviceId: serviceId,
            "projectName": project,
            "assignmentName": input?.trim(),
            "userEmail": localStorage.getItem("emailId"),
            edit: false,
            "status": "Active",
            "comment": comments?.trim()

        }
        let data = new FormData();
        const feedAdd = async () => {
            for (const key in payload) {
                data.append(`${key}`, payload[key]);
            }
            data.append("feedId", Date.now());
            service.addFeed(data, files).then(() => {
                setDisable(false)
                if (msgs.current?.state?.messages?.length) msgs.current.state.messages = []
                msgs.current.show({ id: '1', sticky: true, severity: 'info', summary: '', detail: `Assignment sucessfully created`, closable: true });
                setRefresh(!refresh)
                setTimeout(() => {
                    setShowAddAssignment(false)
                    setInput("")
                    setComments("")
                    setFiles([])
                }, 2000)
            }).catch((err) => {
                setDisable(false)
                if (err.response?.data) {
                    if (msgs.current?.state?.messages?.length) msgs.current.state.messages = []
                    if (typeof (err.response.data) === "object") {
                        msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `${err.response.data.detail}`, closable: true });
                    }
                    else msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `${err.response.statusText}`, closable: true });
                }

            })
        }
        const handleFile = async (e) => {
            if (!payload["assignmentName"] || payload["assignmentName"].trim() === "" || !payload["comment"] || payload["comment"].trim() === "" || !files.length) {
                if (msgs.current?.state?.messages?.length) msgs.current.state.messages = []
                msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `Please fill all fields`, closable: true });
                return;
            }
            else setDisable(true)
            apiService.httpService("post", payload, `${process.env.REACT_APP_API_PARSER_URL}/fileSharingUtility/assignementAddEdit/`).then(() => {
                feedAdd()
            }).catch((err) => {
                setDisable(false)
                if (err.response?.data) {
                    if (msgs.current?.state?.messages?.length) msgs.current.state.messages = []
                    if (typeof (err.response.data) === "object") {
                        msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `${err.response.data.detail}`, closable: true });
                    }
                    else msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: '', detail: `${err.response.statusText}`, closable: true });
                }
            })
        }

        return <div className='col-12 grid m-0 p-0 generalCard justify-content-center'>
            <div className='grid justify-content-center col-12 '>
                <GeneralInput placeHolder="Enter Assignment Name" labelName="Enter Assignment Name:" label={true} Func={setInput} data={input} />
                <GeneralInput placeHolder="Enter Comments Here" labelName="Enter Comments Here:" label={true} Func={setComments} data={comments} />
                <div className="flex m-3 flex-column align-items-center border-1 border-dashed border-400 border-round-md" style={{ padding: "1rem" }}>
                    <div className="col-12 w-full">
                        <span className="mt-3 mb-3 block">Upload Files For Assignment</span>
                        <FileUpload name="demo[]" onRemove={(e) => {
                            let arr = files.filter((item) => item.name !== e?.file?.name)
                            setFiles(arr)
                        }} customUpload={true} auto={true} multiple={true} showUploadButton={false} onSelect={onFileSelect} onClear={() => setFiles(null)}
                            mode="advanced" />
                    </div>
                    <br />
                </div>
                <Messages ref={msgs} className='col-12 text-l' />
            </div>
            <Button
                label="Create"
                disabled={disable}
                style={{ cursor: "pointer", float: "right", backgroundColor: "rgb(12, 98, 145)", color: "white", border: "2px solid rgb(12, 98, 145)", borderRadius: "6px", fontWeight: "300" }}
                className="mb-2 text-l"
                onClick={handleFile}
            />
        </div>
    }
    return (
        <div className='col-12 grid m-0 '>
            {!showDetails ? (
                <div className='col-12 grid m-0 w-full'>
                    <div className='col-7 grid m-0 p-0   align-items-center'>
                        <Button
                            label=""
                            icon="pi custom-pi pi-arrow-left"
                            style={{ backgroundColor: "#0C6291", color: "white", border: "2px solid rgb(12, 98, 145)", borderRadius: "6px", fontWeight: "300" }}
                            className=" text-l mr-4 mb-2"
                            onClick={() => { Func("") }}
                        />
                        <p className='chartHeading text-2xl'>{project}</p>
                    </div>
                    <div className='grid col-12 justify-content-between align-items-center mb-0'>
                        <div className='grid lg:col-9 col-12 m-0 p-0'>
                            <SelectFilter list={statusList} selectedItem={selectedStatts} placeHolder="Select Status" Func={setSelectedStatus} field={"status"} />
                            {/*<SelectFilter list={assigneList} placeHolder="Assigned To" selectedItem={selectedAssigne} Func={setSelectedAssigne} field={"assigned"} />*/}
                            {<div className=' col-12 lg:col-2 p-0 lg:mr-2'>
                                <InputText
                                    id="tollDropdown"
                                    value={searchText}
                                    placeholder="Search"
                                    onChange={(e) => {
                                        const searchTerm = e.target.value;
                                        setSearchText(searchTerm) // Assuming e.target is an input field, and you want to compare its value.
                                        const filteredArr = assignmentData.filter((item) => {
                                            const itemStr = JSON.stringify(item); // Stringify the item object to check for inclusion.
                                            return itemStr.includes(searchText); // Check if searchText is found in the stringified item.
                                        });

                                        setFilteredData(filteredArr); // Update state with the filtered results
                                    }}
                                    className="p-dropdown flex align-items-center bg-white  mb-3"
                                    style={{
                                        borderRadius: '20px',
                                        border: '2px solid #0C6291',
                                        width: "100%"
                                    }}
                                />
                            </div>}
                        </div>
                        <div className='m-0 p-0'>{role !== "Vendor" && !isCompleted && <Button
                            label="Add Assignment"
                            icon="pi pi-plus"
                            style={{ float: "right", backgroundColor: "#0C6291", color: "white", border: "2px solid rgb(12, 98, 145)", borderRadius: "6px", fontWeight: "300" }}
                            className="mr-2 mb-2 text-l"
                            onClick={() => { setShowAddAssignment(!showAddAssignment) }}
                        />}</div>
                    </div>
                    <div className='col-12  p-2 card'>
                        <DataTable
                            emptyMessage="Data not found"
                            className='datatable-box card bifarcation-box col-12'
                            dataKey="id"
                            value={filteredData}
                            style={{ fontSize: "1.1rem", fontWeight: "500" }}
                            responsiveLayout="scroll"
                            rows={10}
                            paginator
                            onRowSelect={(e) => {
                                setShowDetails(true)
                                setAssignment(e.data)
                            }}
                            selectionMode="single"
                        >
                            <Column header="Assignment Name" field='assignmentName' headerClassName='colorX' className='' />
                            <Column header="Status" body={(e) => { return <span className='status-badge text-xl p-1' style={assignment_status_color[`${e.status}`]}>{e.status}</span> }} headerClassName='colorX' />
                            <Column header="Assigned To" field='assigned' headerClassName='colorX' className='' />
                            <Column header="Created On" body={(e) => { return moment(e.Created_time, 'YYYY-MM-DD,h:mm').format('MMM, Do YYYY,h:mm') }} headerClassName='colorX' />
                            <Column header="Last Submission" body={(e) => { return e.lastActivity ? dateFormatter(e.lastActivity) : "-" }} headerClassName='colorX' />
                            <Column header="Delivery Date" body={(e) => { return dateFormatter(e.deliveryDate, "MMM, Do ") }} headerClassName='colorX' />
                            <Column header="Latest Comment" body={(item) => { return item.comment && item.comment.length > 32 ? item.comment?.slice(0, 32) + "....." : item.comment }} headerClassName='colorX' />
                        </DataTable>
                    </div>
                </div>
            ) : (
                <Feed setShowDetails={setShowDetails} projectName={project} assignment={assignment} assignSort={sortFunction} assignmentStatuses={statusList} isProjectCompleted={isCompleted} />
            )}
            <Dialog header="Create Assignment" className='lg:col-6 col-12' visible={showAddAssignment} modal onHide={() => {
                setShowAddAssignment(false)
                setInput("")
                setComments("")
                setFiles([])
                setDisable(false)
            }

            }>
                <div className="col-12">
                    {addAssignment()}
                    {disable && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                </div>
            </Dialog>

        </div>
    )
}
