import React, {useState, memo} from 'react'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

const FileListComp = ({ dataviewGridItem, dataviewListItem, defLayout, headerText, switchLayout, dataviewValue, emptyMessage="", id, header}) => {
    const [layout, setLayout] = useState(defLayout);

    const dataviewHeader = (
        <div className="col-12 flex justify-content-between" >
            <h3 className='m-0'>{headerText}</h3>
            {switchLayout && <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />}
            {header}
        </div>
    );

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === 'list') {
            return dataviewListItem(data);
        }
        else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    return (
        <>
            <DataView alwaysShowPaginator={false} id={id ?? "dv"+ Math.floor(Math.random()*10)} value={dataviewValue} layout={layout} paginator rows={6} itemTemplate={itemTemplate} emptyMessage={emptyMessage} header={dataviewHeader}></DataView>
        </>
    )
}

export default memo(FileListComp)